<template>
  <div>
    <!-- <v-layout>
      <v-btn
        color="warning"
        @click.native="saveStateInfo"
      >
        <v-icon
          id="v-step-5"
          class="pr-2"
        >
          {{ icons.mdiContentSaveAll }}
        </v-icon>
        {{ this.label }}
      </v-btn>
      <v-divider
        class="my-sm-5 mx-5"
        :vertical="$vuetify.breakpoint.smAndUp"
      ></v-divider>
      <v-card-text v-if="this.patientProfile.stateId">
        Saved-Patient: {{ this.patientProfile['FIRST NAME'] }} {{ this.patientProfile['LAST NAME'] }}
      </v-card-text>
      <v-card-text v-if="this.patientProfile.stateId==null">
        No Save State
      </v-card-text>
      <v-snackbar
        v-model="snackbar.show"
        :color="snackbar.color"
        :timeout="1000"
      >
        {{ snackbar.text }}
      </v-snackbar>
    </v-layout> -->
  </div>
</template>

<script>
import { mdiContentSaveAll } from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import { stateService } from '@/render/api/zarya'

export default {
  props: ['patientProfile', 'stateStatus', 'label', 'saveMedscheck'],
  data() {
    return {
      icons: {
        mdiContentSaveAll,
      },
      personalInfo: this.patientProfile,
      lastDateSaveTime: '',
      stateID: this.patientProfile.stateId,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    }
  },
  computed: {
    ...mapGetters(['PatientProfile', 'UserProfile', 'PharmacyProfile']),
    ...mapState(['Patient'], ['User']),
    getFormClass() {
      return this.FormClass
    },
  },
  watch: {
    // patientProfile() {
    //   this.personalInfo = {
    //     ...this.patientProfile,
    //   }
    // },
  },
  methods: {
    // FIXME deprecated
    savePatientInfo() {
      // save file
      this.$store.dispatch('saveFilePatientData')
      this.lastDateSaveTime = this.$store.state.Patient.when
    },
    async saveStateInfo() {
      // this.saveCurrentDate()
      let promise
      const data = {
        formType: this.$store.state.Patient.FORM_CLASS,
        state: this.personalInfo,
        patientID: this.$store.state.Patient.data.PatientID,
        userID: this.$store.state.User.user._id,
        pharmacyID: this.$store.state.User.pharmacy._id,
      }
      const token = await this.$auth.getTokenSilently()

      // if (this.$store.state.Patient.data.stateId) {
      //   // update it
      //   this.showMsg(
      //     `Successfully UPDATED SAVED FORM for Patient: ${this.personalInfo['FIRST NAME']} ${this.personalInfo['LAST NAME']}`,
      //   )
      //   promise = stateService.updateState(token, this.personalInfo.stateId, {
      //     ...data,
      //     status: this.stateStatus || 'in-progress',
      //   })
      //   this.$store.dispatch('updatePatientData', this.personalInfo)
      // } else {
      // create it
      this.showMsg(
        `Successfully CREATED NEW SAVED FORM for Patient: ${this.personalInfo['FIRST NAME']} ${this.personalInfo['LAST NAME']}`,
      )
      promise = stateService.createState(token, data)
      this.$store.dispatch('updatePatientData', this.personalInfo)

      // }

      return promise.then(this.saveResponseSuccess, this.saveResponseReject)
    },

    // FIXME deprecated
    async loadStateInfo() {
      const token = await this.$auth.getTokenSilently()
      if (this.stateID) {
        return stateService.getStateById(token, this.stateID).then(state => {
          this.showMsg('Successfully loaded your form')
          this.$emit('onload', state)
        })
      }
    },

    // FIXME update to a nicer looking dialog
    saveResponseSuccess(res) {
      if (!res) {
        return this.saveResponseReject(res)
      }

      // this.showMsg(
      //   `Successfully saved your form for Patient: ${this.personalInfo['FIRST NAME']} ${this.personalInfo['LAST NAME']}`,
      // )
      this.personalInfo.stateId = res.state._id
      this.stateID = res.state._id
      this.$store.dispatch('updatePatientData', this.personalInfo)
    },

    // FIXME update to a nicer looking dialog
    saveResponseReject(res) {
      this.showMsg('Failed to saved your form', 'error')
    },
    showMsg(text, color = 'success') {
      this.snackbar.show = true
      this.snackbar.text = text
      this.snackbar.color = color
    },

    // FIXME deprecated
    // loadPatientInfo(){
    //   this.$store.dispatch("loadFilePatientData").then( (state) => {
    //     this.$emit('onload', state);
    //   });
    // }
    saveCurrentDate() {
      const current = new Date()
      const date = `${current.getFullYear()}/${
        current.getMonth() + 1
      }/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.personalInfo.lastSavedDate = dateTime

      // this.personalInfo.lastSavedDate = `${d.getFullYear()}-${d.getMonth()}-${d.getDay()} ${d.getHours()}:${d.getMinutes()}`
    },
  },
}
</script>

<style scoped>
</style>
