<template>
  <v-card data-intercom-target="PrescriptionList">
    <v-card-title>
      <p class="tw-text-blue-900 tw-text-2xl tw-font-bold dark:tw-text-white dark:tw-text-opacity-50">
        Prescription List (Last 365 days)
      </p>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectedMedication.length < 1 && $route.name === 'patient-profile'"
        class="ma-2"
        color="primary"
        @click="setSelectedPatientMedications"
      >
        To Form Hub
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      v-model="selectedMedication"
      :search="search"
      :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50, -1]}"
      disable-items-per-page
      :headers="headers"
      :items="detailedMedicationsList"
      :items-per-page="30"
      item-key="RxNumber"
      :single-select="singleSelect"
      show-select
      fixed-header
      event
      sort-by="RxNumber"
      :sort-desc="[true]"
      :loading="loading"
      @click:row="rowClick"
    >
      <template v-slot:header.data-table-select>
      </template>
      <template
        #[`item.selectedDrug`]="{ item }"
      >
        <v-icon
          color="success"
          medium
          class="me-3"
          @click="selectRow(item)"
        >
          {{ icons.mdiPencilOutline }}
        </v-icon>
      </template>
      <template
        v-for="header in headers.filter((header) =>
          header.hasOwnProperty('formatter')
        )"
        v-slot:[`item.${header.value}`]="{ header, value }"
      >
        {{ header.formatter(value) }}
      </template>
      <template #[`item.Status`]="{item}">
        <v-chip
          :hidden="!item.Status"
          small
          class="v-chip-light-bg"
          :color="statusList[item.Status] || ''"
          :class="`${statusList[item.Status]}--text`"
        >
          {{ translateList[item.Status] }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiPencilOutline } from '@mdi/js'
import { momentTimeZone } from '@/util/functions/index'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SingleDrug',
  props: ['selected', 'searched'],
  setup() {
    const statusColor = { 1: 'warning' }

    return { statusColor }
  },
  data() {
    return {
      statusList: {
        1: 'orange',
        COM: 'success',
        AMD: 'success',
        CAN: 'warning',
        HOL: 'orange',
        I: 'error',
        F: 'primary',
        CH: 'warning',
        T: 'grey',
      },
      translateList: {
        1: 'Hold',
        COM: 'Complete',
        AMD: 'Amended',
        CAN: 'Cancelled',
        HOL: 'Hold',
        I: 'Inactive',
        F: 'New Rx',
        CH: 'CAN/HOL',
        T: 'Transferred Out',
      },
      loading: false,
      detailedMedicationsList: [],
      selectedMedication: this.selected || [],
      search: this.searched || '',
      RxNumber: '',
      singleSelect: true,
      rowIndex: '',

      // groupby: 'GenericName',
      headers: [
        {
          text: 'Date',
          value: 'RxDate',
          groupable: false,
          formatter: x => (x ? momentTimeZone(x) : null),
        },
        {
          text: 'Brand Name',
          value: 'BrandName',
        },
        {
          text: 'Generic Name',
          value: 'GenericName',
          sortable: false,
        },
        {
          text: 'Strength',
          value: 'RxStrength',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Dr Last Name',
          value: 'LastName',
        },
        {
          text: 'Rx Number',
          value: 'RxNumber',
          groupable: false,
        },
        {
          text: 'Rx Status',
          value: 'Status',
          groupable: false,
        },
        {
          text: 'Rx Hold',
          value: 'hold',
          groupable: false,
        },
      ],
      icons: {
        mdiPencilOutline,
      },
    }
  },
  watch: {
    selectedMedication() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  created() {
    this.getSearchByRx()
  },
  methods: {
    // rowClick(item, row) {
    //   row.select(true)

    //   // item.name - selected id
    // },
    setSelectedPatientMedications() {
      this.$store.dispatch('setSelectedPatientMedications', this.selectedMedication)

      // Go to form hub
      this.$router.push({ name: 'formhub' })
    },
    rowClick(item, row) {
      if (this.rowIndex === '') {
        this.rowIndex = row.index
        row.select(true)
      } else if (this.rowIndex === row.index) {
        row.select(false)
        this.rowIndex = ''
      } else {
        this.rowIndex = row.index
        row.select(true)
      }
    },
    async getSearchByRx() {
      this.loading = true
      this.detailedMedicationsList =
        (await this.$root.$system.DBAdapterConn.getSingleRecentRx(
          this.PatientProfile.PatientID,
          this.rxNumber,
          this.genericName,
          this.brandName,
          this.mixName,
          this.LastName,
          this.FirstName,
        )) || []
      this.loading = false
    },
    select() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  computed: {
    ...mapGetters(['PatientProfile']),
    ...mapState(['User']),
  },
}
</script>
