<template>
    <div>
        <v-dialog v-model="dialog" persistent>
            <v-card :key="refresh">
                <v-card-title>
                    <span class="headline">Doctor Search</span>
                </v-card-title>
                <v-card-text class="text-xs-left px-5">
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 md4>
                                <v-text-field label="Doctor First Name" v-model="drFirstName"></v-text-field>
                            </v-flex>
                            <v-flex xs12  md4>
                                <v-text-field label="Doctor Last Name" v-model="drLastName"></v-text-field>
                            </v-flex>
                            <v-flex xs12  md4>
                                <v-text-field label="CPSO" v-model="cpso"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                    <small>*Search by either</small>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click.native="dialog = false">close</v-btn>
                <v-btn color="primary" @click.native="searchDoctor">Search</v-btn>
                </v-card-actions>
            </v-card>


            <v-data-table
                v-show="doctorList.length"
                v-model="selectedDoctor"
                hide-default-footer
                :items="doctorList"
                item-key="CPSO"
                :headers="headers"
                class=""
            >
                <template slot="headers">
                    <tr>
                        <th class="black--text" width="10%" align="left">Dr. First Name</th>
                        <th class="black--text" width="10%" align="left">Dr. Last Name</th>
                        <th class="black--text" width="15%" align="left">CPSO</th>
                    </tr>
                </template>

                <template v-slot:item="props">
                    <tr :active="props.selected" @click="selected(props)">
                        <td width="10%" align="left">{{ props.item[`DoctorFirstName`] }}</td>
                        <td width="10%" align="left">{{ props.item[`DoctorLastName`] }}</td>
                        <td width="15%" align="left"><strong>{{props.item[`CPSO`]}}</strong></td>
                    </tr>
                </template>

                <template slot="no-data">
                    <tr>
                        <td colspan="6" class="pa-4">
                            No doctors to select from
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        refreshNum :{
            required: false
        }
    },
    watch: {
        refresh: function() {
            this.searchDoctor();
        }
    },
  data(){
      return {
          refresh: true,
          dialog: false,
          drFirstName: '',
          drLastName: '',
          cpso: '',
          doctorList: [],
          selectedDoctor: [],
          headers: [
            { text: 'Last Name', class: "black--text font-weight-bold subtitle-1", align: 'left', value: 'DoctorLastName', sortable: false},
            { text: 'First Name', class: "black--text font-weight-bold", align: 'left', value: 'DoctorFirstName', sortable: false},
            { text: 'CPSO', class: "black--text font-weight-bold", align: 'left', value: 'CPSO', sortable: false},
          ],
          onDoctorSelection: () => {},
      };
  },
  created() {
        if(!window.searchDoctor){
            window.searchDoctor = {};
        }

        // FIXME expose store to window and allow dispatching this action
        window.searchDoctor.open = (callbackDoctorSelection) => {
            this.dialog = true;
            this.refresh++;
            // set the defined callback
            this.onDoctorSelection = callbackDoctorSelection;
        };

        window.searchDoctor.close = () => {
            this.dialog = false;
        }

        // this.searchDoctor();
  },
  methods: {
      selected(doctor){
          // fire the callback
          this.onDoctorSelection(doctor.item);
          // close the modal
          this.dialog = false;
      },
      async searchDoctor(){
          this.doctorList = await this.$root.$system.DBAdapterConn.getSearchByDoctor(
              this.PatientProfile.PatientID,
              this.drFirstName,
              this.drLastName,
              this.cpso
          ) || [];
      }
  },
  computed: {
    ...mapGetters(["PatientProfile"])
  },
};
</script>

<style scoped>
</style>
