<template>
<v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
        <v-card class="pt-10">
            <v-card-title class="justify-center flex-column">

                <span class="mb-2">{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</span>

                <v-chip label small>
                    {{ patientProfile['LAST NAME']}}
                </v-chip>
            </v-card-title>

            <!-- <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiCheck }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.taskDone) }}
              </h3>
              <span>Task Done</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiBriefcaseVariantOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(userData.projectDone) }}
              </h3>
              <span>Project Done</span>
            </div>
          </div>
        </v-card-text>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">
            Details
          </h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Username:</span>
              <span class="text--secondary">{{ userData.username }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium text-no-wrap me-2">Billing Email:</span>
              <span class="text--secondary">{{ userData.email }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Status:</span>
              <span class="text--secondary">
                <v-chip
                  small
                  label
                  :color="resolveUserStatusVariant(userData.status)"
                  :class="`v-chip-light-bg ${resolveUserStatusVariant(userData.status)}--text font-weight-medium text-capitalize`"
                >
                  {{ userData.status }}
                </v-chip>
              </span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Role:</span>
              <span class="text--secondary text-capitalize">{{ userData.role }}</span>
            </v-list-item>
            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Tax ID:</span>
              <span class="text--secondary">{{ userData.taxId }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Contact:</span>
              <span class="text--secondary">+1 {{ userData.contact }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Language:</span>
              <span class="text--secondary">{{ userData.language }}</span>
            </v-list-item>

            <v-list-item
              dense
              class="px-0 mb-n2"
            >
              <span class="font-weight-medium me-2">Country:</span>
              <span class="text--secondary">{{ userData.country }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn>
          <v-btn
            color="error"
            outlined
          >
            Suspended
          </v-btn>
        </v-card-actions> -->
        </v-card>

    </v-col>

</v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle } from '@mdi/js'
import { kFormatter } from '@core/utils/filter'
import { mapGetters } from 'vuex'
// import UserBioEdit from './UserBioEdit.vue'

export default {
  components: {},
  data() {
    return {
      patientProfile: {
        ...JSON.parse(JSON.stringify(this.$store.state.Patient.data)),
      },
    }
  },
  computed: {
    ...mapGetters(['PatientProfile', 'UserProfile']),
  },
  setup() {
    // ui

    return {
      kFormatter,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
