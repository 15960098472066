<template>
  <v-row>
    <v-col cols="12">
      <v-dialog
        ref="dialog"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="dateSelected"
            label="Date Picker"
            :prepend-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker
          v-model="dateSelected"
          :allowed-dates="(dateSelected) => dateSelected <= new Date().toISOString().substr(0, 10)"
          scrollable
          color="primary"
        >
          <v-btn
            text
            color="primary"
            @click="modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="refreshRxTodayList"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCalendar } from '@mdi/js'

export default {
  name: 'DayPickerDialog',
  props: {
    date: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modal: false,
      dateSelected: this.date,
      icons: {
        mdiCalendar,
      },
    }
  },
  methods: {
    refreshRxTodayList() {
      this.$emit('refreshRxTodayList', this.dateSelected)
      this.modal = false
    },
  },
}
</script>
