<template>
  <div class="text-center">
    <v-btn
      type="submit"
      :disabled="disabled"
      color="success"
      large
      block
      @click.native="submit"
    >
      <slot></slot>
      <v-icon
        right
        dark
      >
        cloud_upload
      </v-icon>
    </v-btn>
    <v-alert
      v-if="status.text"
      :value="status.text"
      :type="status.type"
    >
      {{ status.text }}
    </v-alert>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline text-xs-center">
          Submitting...
        </v-card-title>
        <v-card-text class="text-xs-center">
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { userService } from '@/render/api/zarya'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    formType: {
      required: true,
      type: String,
    },
    baseFilename: {
      require: true,
      type: String,
    },
    patientData: {
      required: true,
      type: Object,
    },
    typeOfMedscheck: {
      required: false,
      type: String,
    },
    patientDataDecorator: {
      require: false,
      type: Function,
    },
  },
  computed: {
    ...mapGetters(['PharmacyProfile', 'UserProfile']),
    ...mapState(['Patient'], ['User']),
  },
  data() {
    return {
      user: this.$auth.user.name,
      dialog: false,
      submittedPatientData: this.patientData,
      status: {
        type: '',
        text: '',
      },
    }
  },
  created() {
    this.$store.subscribeAction((action, state) => {
      switch (action.type) {
        case 'submitPatientData':
          this.dialog = true
          break
        case 'pdfProcessSuccess':
          this.dialog = false
          this.status = {
            type: 'success',
            success: 'Successful submission',
          }
          break
        case 'pdfProcessFail':
          this.dialog = false
          this.status = {
            type: 'error',
            success: 'Failed to submit',
          }
          break
      }
    })
  },
  methods: {
    async SavePoints() {
      let points = 10
      if (this.formType === 'medscheck') {
        points = 60
      }
      const token = await this.$auth.getTokenSilently()
      await userService.addPoints(token, points, this.$store.getters.UserProfile._id)
    },
    async submit() {
      this.SavePoints()

      // will go through this if statement if there is additional work being done on submit
      if (this.patientDataDecorator) {
        this.submittedPatientData = this.patientDataDecorator(this.patientData)
        this.dialog = true
        await this.$store
          .dispatch('pdfSubmitData', {
            filename: `${this.baseFilename}-${this.submittedPatientData['LAST NAME']}`,
            formType: this.formType,
            pdfData: this.submittedPatientData,
          })
          .then(
            async pdfRef => {
              this.dialog = false
              this.status = {
                type: 'success',
                success: 'Successful submission',
              }
              const token = await this.$auth.getTokenSilently()
              this.$store.dispatch('pdfDownload', {
                token,
                id: pdfRef.id,
                filename: `${pdfRef.filename.toLowerCase()}`,
              })
              await this.$emit('postaction', this.submittedPatientData)
              window.scrollTo(0, 0)
              this.savePdfInfo(pdfRef)
            },
            err => {
              // FIXME finally isn't kicking in
              this.dialog = false
              this.status = {
                type: 'error',
                success: err,
              }
              window.scrollTo(0, 0)
            },
          )
      } else {
        await this.$emit('preaction', this.patientData)

        // if no addditional work on submit is needed, will follow through with this
        this.dialog = true
        await this.$store
          .dispatch('pdfSubmitData', {
            filename: `${this.baseFilename}-${this.patientData['LAST NAME']}`,
            formType: this.formType,
            pdfData: this.patientData,
          })
          .then(
            async pdfRef => {
              this.dialog = false
              this.status = {
                type: 'success',
                success: 'Successful submission',
              }

              //

              const token = await this.$auth.getTokenSilently()
              this.$store.dispatch('pdfDownload', {
                token,
                id: pdfRef.id,
                filename: `${pdfRef.filename.toLowerCase()}`,
              })
              await this.$emit('postaction', this.patientData)
              window.scrollTo(0, 0)
              this.savePdfInfo(pdfRef)
            },
            err => {
              // FIXME finally isn't kicking in
              this.dialog = false
              this.status = {
                type: 'error',
                success: err,
              }
              window.scrollTo(0, 0)
            },
          )
      }
      console.log('clear')
      this.$store.dispatch('clearSelectedPatientMedications')
    },
    savePdfInfo(pdfRef) {
      this.$emit('saveInfo', pdfRef)
    },
  },
}
</script>
<style scoped>
</style>
