<template>
  <v-container
    fluid
    ma-0
    pa-0
    grid-list-xl
    fill-height
    text-xs-center
    style="width: 100%;"
  >
    <v-row>
      <v-col
        xs12
        text-xs-left
      >
        <slot name="headline"></slot>
        <v-card :color="baseColor">
          <v-card-title>
            <h1><slot name="title"></slot></h1>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <slot name="content"></slot>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
    props: {
        color: {
            default: 'white',
            type: String
        }
    },
    data() {
        return {
            baseColor: this.color
        }
    }
}
</script>

<style scoped>
h1{
    width: 100%;
}
</style>
