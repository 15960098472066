<template>
  <v-row class="d-flex justify-center align-center">
    <v-col>
      <v-btn
        v-if="activeStep > 1"
        class="mx-2"
        color="warning"
        large
        @click="previous"
      >
        Previous
      </v-btn>
      <v-btn
        v-if="activeStep < finalStep"
        :disabled="disabledStep"
        color="success"
        large
        @click="next"
      >
        Next
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ['step', 'finalStep', 'disabledButton'],
  computed: {
    activeStep() {
      return this.step
    },
    disabledStep() {
      return this.disabledButton
    },
  },
  methods: {
    previous() {
      this.$emit('previous', this.activeStep)
    },
    next() {
      this.$emit('next', this.activeStep)
    },
  },
}
</script>

<style>
</style>
