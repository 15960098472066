<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card v-if="enabledContent">
          <v-card-title v-if="enabledHeader">
            <slot name="header"></slot>
          </v-card-title>
          <slot name="content"></slot>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    computed: {
        enabledHeader() {
            return !!this.$slots.header
        },
        enabledContent() {
            return !!this.$slots.content
        },
    },
}
</script>

<style scoped>
/* .subheader{
        hack
        height: 0px;
        transform: translateY(40px);
    } */
</style>
