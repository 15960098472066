/**
 * capitalizes the first letter of a string or group of strings
 * @param  {} str
 */
const changeNameCase = str => {
  if (typeof str === 'string' || str instanceof String) {
    return str.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ')
  }
  throw 'Argument requires a string'
}

export default changeNameCase
