<template>
  <div>
    <!-- Patient Sig -->
    <div v-if="formType === 'medscheck' || formType === 'vacationSupply'">
      <div>
        <v-radio-group
          v-model="verbalConsent"
          column
        >
          <v-radio
            label="Click here to sign as COVID-19 Verbal Consent"
            value="Yes"
            @click="injectCovidConsent"
          ></v-radio>
          <v-radio
            label="Click here to sign a Typed Signature for the Patient"
            value="No"
            @click="injectPatientName"
          ></v-radio>
        </v-radio-group>
      </div>
      <div
        class="tw-justify-center tw-align-center"
      >
        <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-transparent tw-justify-center">
          <div class="  tw-bg-transparent tw-flex tw-justify-center">
            <v-text-field
              v-model="patientName"
              class=""
              label="Typed Signature"
              suffix=""
              outlined
              hide-details
            >
            </v-text-field>
          </div>
        </div>
        <div class="tw-grid tw-justify-items-stretch">
          <div
            id="signatureTextTyped"
            class="tw-place-items-center tw-justify-self-auto tw-content-center tw-h-64 tw-grid tw-grid-rows-1 tw-mt-12 tw-bg-transparent  tw-justify-center"
          >
            <p
              id="signatureTextFont"
              class="tw-px-8 tw-mx-8 tw-pb-10 tw-mb-6 tw-text-center tw-bg-transparent tw-italic tw-font-serif tw-text-5xl tw-text-black"
            >
              {{ changeNameCase(patientName) }}
            </p>
          </div>
        </div>
        <v-alert
          v-if="this.injectedPatient"
          color="primary"
          dark
        >
          Injected Successfully.
        </v-alert>
      </div>
    </div>
    <!-- Pharmacist Sig -->
    <div v-if="$listeners.updatePharmacist">
      <div>
        <v-radio-group
          v-model="pharmacistSignatureOption"
          column
        >
          <v-radio
            label="Pharmacist E-Signature"
            value="esign"
            @click="injectRphSignature"
          ></v-radio>
          <v-radio
            label="Pharmacist Signature Pad"
            value="pad"
            @click="refreshSignatureStatus++"
          ></v-radio>
        </v-radio-group>
      </div>
      <div
        v-if="pharmacistSignatureOption === 'esign'"
        class="tw-justify-center tw-align-center"
      >
        <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-transparent tw-justify-center">
          <div class="  tw-bg-transparent tw-flex tw-justify-center">
            <pharmacist-search></pharmacist-search>
            <v-text-field
              v-model="pharmacistName"
              class=""
              label="Pharmacist name"
              suffix=""
              outlined
              :append-icon="icons.mdiArrowExpand"
              @click:append="openPharmacistOverlay"
            >
              hide-details
              >
            </v-text-field>
          </div>
        </div>
        <div
          v-if="pharmacistName"
          class="tw-grid tw-justify-items-stretch"
        >
          <div
            id="signatureTextTyped"
            class="tw-place-items-center tw-justify-self-auto tw-content-center tw-h-64 tw-grid tw-grid-rows-1 tw-mt-12 tw-bg-transparent  tw-justify-center"
          >
            <p
              id="signatureTextFont"
              class="tw-capitalize tw-px-8 tw-mx-8 tw-pb-10 tw-mb-6 tw-text-center tw-bg-transparent tw-italic tw-font-serif tw-text-5xl tw-text-black"
            >
              {{ this.capitalizeFirstLetter(pharmacistName) }}
            </p>
          </div>
        </div>
        <v-alert
          v-if="this.injectedPharmacist"
          color="primary"
          dark
        >
          Injected Successfully.
        </v-alert>
      </div>
      <div v-if="pharmacistSignatureOption === 'pad'">
        <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-transparent tw-justify-center">
          <div class="  tw-bg-transparent tw-flex tw-justify-center">
            <pharmacist-search></pharmacist-search>
            <v-text-field
              v-model="pharmacistName"
              class=""
              label="Pharmacist name"
              suffix=""
              outlined
              :append-icon="icons.mdiArrowExpand"
              @click:append="openPharmacistOverlay"
            >
              hide-details
              >
            </v-text-field>
          </div>
        </div>
        <div class="Signaturecontainer">
          <div class="Signaturecontainer">
            <div
              v-show="!initial"
              class="d-flex justify-center align-center"
            >
              <VueSignaturePad
                id="signature"
                :key="refreshSignatureStatus"
                ref="signaturePad"
                class="border-solid border-4 border-black"
                width="700px"
                height="200px"
                :options="{ onBegin, onEnd }"
              />
            </div>
          </div>
        </div>
        <div class="buttons">
          <v-btn
            color="primary"
            @click="undoSignaturePad"
          >
            Undo
          </v-btn>
        </div>
      </div>
    </div>
    <!-- DatePicker -->
    <div class="tw-grid tw-grid-cols-3 tw-gap-4 tw-bg-transparent tw-justify-center">
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="signatureDate"
            label="Signature Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="signatureDate"
          @input="updateDate"
        ></v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

import { mdiArrowExpand } from '@mdi/js'
import { UltimateTextToImage } from 'ultimate-text-to-image'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'
import SignaturePad from '@/components/common/ui/Form/SignaturePad/SignaturePad.vue'
import { changeNameCase, capitalizeFirstLetter } from '@/util/index'
import dayPickerDialog from '@/components/datePickers/dayPickerDialog.vue'

export default {
  components: {
    SignaturePad,
    dayPickerDialog,
    'pharmacist-search': PharmacistSearch,
  },
  data() {
    return {
      defaultPatientName: `${this.$store.state.Patient.data['FIRST NAME']} ${this.$store.state.Patient.data['LAST NAME']}`,
      pharmacistName: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
      patientName: `${this.$store.state.Patient.data['FIRST NAME']} ${this.$store.state.Patient.data['LAST NAME']}`,
      ocp: '',

      initial: false,
      injectedPatient: false,
      injectedPharmacist: false,

      verbalConsent: 'No',

      pharmacistSignatureOption: 'esign',
      patientSignatureOption: 'esign',

      refresh: 0,
      dateMenu: false,

      pharmacistSig: '',
      patientSig: '',

      // currentDate: moment().format('YYYY-MM-DD'),
      signatureDate: moment().format('YYYY-MM-DD'),

      refreshSignatureStatus: 0,
      loadingSignature: false,
      icons: {
        mdiArrowExpand,
      },

      formType: this.$store.state.Patient.FORM_CLASS,
    }
  },
  computed: {
    ...mapState(['Patient', 'User']),
  },
  mounted() {
    this.injectRphSignature()
    if (this.formType === 'medscheck' || this.formType === 'vacationSupply') {
      this.injectPatientSignature()
    }
  },
  methods: {
    capitalizeFirstLetter,
    changeNameCase,
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.pharmacistName = changeNameCase(UserName)
        this.ocp = PharmID
        this.$emit('updatePharmacist', {
          pharmacistName: changeNameCase(this.pharmacistName),
          ocp: this.ocp,
        })
        this.injectRphSignature()
      })
    },
    updateDate() {
      this.saveSignature()
      this.dateMenu = false
    },
    onBegin() {},
    async onEnd() {
      if (this.refreshSignatureStatus === 0) {
        this.refreshSignatureStatus += 1
      }
      await this.saveSignaturePad()
      this.canvaslocal = this.$refs.signaturePad.$refs.signaturePadCanvas
      if (this.pharmacistSignatureOption === 'pad') {
        await this.injectRphSignature()
      }
      this.saveSignature()
    },
    saveSignaturePad() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()

      return data
    },
    undoSignaturePad() {
      this.$refs.signaturePad.undoSignature()
    },
    saveSignature() {
      this.$emit('save', {
        patientSig: this.patientSig,
        pharmacistSig: this.pharmacistSig,
        signatureDate: this.signatureDate,
      })
    },
    async injectRphSignature() {
      this.pharmacistSig =
        this.pharmacistSignatureOption === 'esign'
          ? await this.injectSignature(changeNameCase(this.pharmacistName))
          : await this.saveSignaturePad()
      this.saveSignature()
    },
    async injectPatientSignature() {
      this.patientSig = await this.injectSignature(changeNameCase(this.patientName))
      this.saveSignature()
    },
    async injectSignature(data) {
      const textToImage = new UltimateTextToImage(`${data}`, {
        fontFamily: 'Robertson',
        fontSize: 80,
        fontStyle: 'italic',
      }).render()
      const dataUrlPng = textToImage.toDataUrl()

      return dataUrlPng
    },
    injectCovidConsent() {
      this.patientName = 'COVID-19: Verbal Consent'
      this.injectPatientSignature()
    },
    injectPatientName() {
      this.patientName = this.defaultPatientName
      this.injectPatientSignature()
    },
  },
}
</script>

<style lang="scss" scoped>
#signateCovidBox {
  width: 400px;
  height: 200px;
}

#signatureTextFont {
  font-family: 'Robertson';
  font-size: 40px;
}

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}

#signaturePatient {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}

//signature Button
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}

.SignaturecontainerPat {
  width: '100%';
  padding: 8px 16px;
}
</style>
