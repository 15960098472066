<template>
  <div :key="componentKeyinteractions">
    <v-card
      id="patientformpanel"
      color="info"
      dark
    >
      <v-card-title class="justify-left">
        Adapt a Prescription
      </v-card-title>
      <v-row
        v-if="patientProfile.PatientID"
        :key="componentTopCardKey"
      >
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</h2>
            <p>Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }} </p>
          </v-card-text>
        </v-col>
        <v-col
          v-if="selectedMedication.length > 0"
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>{{ patientProfile['BRANDNAME'] }} {{ patientProfile['RXSTRENGTH'] }}</h2>
            <p> {{ patientProfile['RXNUMBER'] }}||Date: {{ this.$moment(this.patientProfile['RXDATE']).format('YYYY-MM-DD') }}||DIN: {{ patientProfile['DIN'] }}</p>
          </v-card-text>
        </v-col>
        <v-col
          v-if="selectedMedication.length > 0"
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text
            class="text-center"
          >
            <h2>Dr. {{ patientProfile['PRIMARY FIRST NAME'] }} {{ patientProfile['PRIMARY LAST NAME'] }}</h2>
            <p>Fax: {{ patientProfile['PRIMARY FAX #'] }} || Tel: {{ (this.patientProfile['PRIMARY PHONE #']) }} </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-stepper
      v-model="activeStep"
      class="custom-header"
    >
      <!-- Header -->
      <v-stepper-header>
        <!-- Header: Step 1 -->
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Medication List</span>
              <span class="text--secondary text-xs">Select Medication to Adapt</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <!-- Header: Step 2 -->
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Details</span>
              <span class="text--secondary text-xs">Lets Classify the Adaptation</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Form Signoff</span>
              <span class="text--secondary text-xs">Form Submission</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>
      <!-- Stepper Items -->
      <v-stepper-items>
        <!-- Stepper Content: Step 1 -->
        <v-stepper-content step="1">
          <v-card
            id="medListId"
            :key="componentKeyMedList"
          >
            <single-medication-selection
              :selected="selectedMedication"
              @medicationupdatelist="selected"
            >
            </single-medication-selection>
          </v-card>
        </v-stepper-content>
        <!-- Stepper Content: Step 2 -->
        <v-stepper-content step="2">
          <v-expansion-panels v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Adaptation Considerations</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form class="multi-col-validation">
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <div id="checkallcheckbox">
                        <v-list-item>
                          <v-list-item-content>
                            <v-btn
                              rounded
                              color="primary"
                              @click="checkAllSecondary()"
                            >
                              Check All
                              <v-icon
                                dark
                                right
                              >
                                {{ icons.mdiCheckboxMarkedCircleOutline }}
                              </v-icon>
                            </v-btn>
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-col>
                    <v-col
                      cols="12"
                      md="9"
                    >
                      <v-list>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.optionalCheck1"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('optionalCheck1')">
                            Drug is safe and effective considering: History, symptoms, precautions, potential risks and benefits
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck1"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck1')">
                            Benefits outweigh the risks to the patient requiring adapting or renewing the prescription
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck2"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck2')">
                            Prescription renewal or adaptation is in the best interest of the patient
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck3"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck3')">
                            Pharmacy is in possession of the existing order or prescription to be renewed or adapted, or has access to the information contained in the original prescription (copy, verbal confirmation from the pharmacy, or medical record)
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.requiredCheck4"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('requiredCheck4')">
                            Adaptation is based on the circumstances of the particular patient by altering the:
                            <v-list-item-subtitle class="subtitle-2">
                              - Dose
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="subtitle-2">
                              - Dose Form
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="subtitle-2">
                              - Regimen
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="subtitle-2">
                              - Route of Adminstration
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Adaption Details
                  <v-icon
                    v-if="adaptionChoice === ''"
                    color="warning"
                    medium
                  >
                    {{ icons.mdiAlert }}
                  </v-icon></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-form class="multi-col-validation">
                  <v-row>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-select
                        v-model="adaptionChoice"
                        :items="adaptationChoices"
                        label="Select Adaptation Detail"
                        @input="adaptationChoiceToggler()"
                      ></v-select>
                    </v-col>

                    <template v-if="adaptionChoice === 'Dose Change'">
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-list subheader>
                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox
                                v-model="patientProfile.doseIncrease"
                                true-value="yes"
                                @change="dosageToggle('increase')"
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content @click="dosageToggle('increase')">
                              <v-list-item-subtitle>Increase</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-action>
                              <v-checkbox
                                v-model="patientProfile.doseDecrease"
                                true-value="yes"
                                @change="dosageToggle('decrease')"
                              ></v-checkbox>
                            </v-list-item-action>
                            <v-list-item-content @click="dosageToggle('decrease')">
                              <v-list-item-subtitle>Decrease</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-textarea
                          v-model="patientProfile.doseReason"
                          label="Reason"
                        ></v-textarea>
                      </v-col>
                    </template>

                    <template v-if="adaptionChoice === 'Dosage Form'">
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          v-model="patientProfile.originalDose"
                          label="Original Dosage"
                        ></v-text-field>
                        <v-text-field
                          v-model="patientProfile.newDose"
                          label="New Dosage"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-textarea
                          v-model="patientProfile.doseFormReason"
                          label="Reason"
                        ></v-textarea>
                      </v-col>
                    </template>
                    <template v-if="adaptionChoice === 'Regimen'">
                      <v-col
                        cols="12"
                        md="9"
                      >
                        <v-textarea
                          v-model="patientProfile.regimenReason"
                          label="Reason"
                        ></v-textarea>
                      </v-col>
                    </template>

                    <template v-if="adaptionChoice === 'ROA'">
                      <v-col
                        cols="12"
                        md="3"
                      >
                        <v-text-field
                          v-model="patientProfile.originalRoute"
                          label="Original Route"
                        ></v-text-field>

                        <v-text-field
                          v-model="patientProfile.adaptedRoute"
                          label="Adapted Route"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="auto"
                        xs="12"
                        sm="6"
                      >
                        <v-textarea
                          v-model="patientProfile.roaReason"
                          label="Reason"
                        ></v-textarea>
                      </v-col>
                    </template>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
        <!-- Stepper Content: Step 3 -->
        <v-stepper-content step="3">
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Signature
                  <v-icon
                    v-if="patientProfile.PharmacistSignature === ''"
                    color="warning"
                    medium
                  >
                    {{ icons.mdiAlert }}
                  </v-icon></span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <pg-sig-input
                  :key="signatureCompKey"
                  @updatePharmacist="updatePharmacist"
                  @save="saveSignature"
                ></pg-sig-input>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Patient Information: {{ patientProfile['LAST NAME'] }}, {{ patientProfile['FIRST NAME'] }} </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="patientProfile['FIRST NAME']"
                      label="First Name"
                      type="text"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    sm="6"
                  >
                    <v-text-field
                      v-model="patientProfile['LAST NAME']"
                      label="Last Name"
                      type="text"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Medication: {{ patientProfile['BRANDNAME'] }} {{ patientProfile['RXSTRENGTH'] }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card id="rxinfoid">
                  <v-card-text>
                    <template>
                      <v-form class="multi-col-validation">
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXNUMBER']"
                              label="RX Number"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXDATE']"
                              label="RX Date"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['BRANDNAME']"
                              label="Brand Name"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['GENERICNAME']"
                              label="Generic Name"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['DAYS']"
                              label="Day Supply"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXQUANTITYDISPENSE']"
                              label="Quantity"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['RXSTRENGTH']"
                              label="Drug Strength"
                              type="text"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            md="6"
                            sm="6"
                          >
                            <v-text-field
                              v-model="patientProfile['SIG']"
                              label="SIG"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </template>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Doctor Information: Dr. {{ patientProfile['PRIMARY FIRST NAME'] }} {{ patientProfile['PRIMARY LAST NAME'] }}</span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container column>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="7"
                      md="3"
                      justify-start
                    >
                      <doctor-search :refresh-num="refresh"></doctor-search>
                      <v-btn
                        id="v-step-4"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openDrOverlay"
                      >
                        Select a Doctor
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="patientProfile['PRIMARY FIRST NAME']"
                        label="Doctor First Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="4"
                    >
                      <v-text-field
                        v-model="patientProfile['PRIMARY LAST NAME']"
                        label="Doctor Last Name"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="7"
                      md="7"
                      justify-start
                    >
                      <v-list ubheader>
                        <v-list-item>
                          <v-list-item-action>
                            <v-checkbox
                              v-model="patientProfile.doctorNotified"
                              true-value="yes"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content @click="wordClickToggle('doctorNotified')">
                            <v-list-item-subtitle class="subtitle-2">
                              Has the doctor been notified?
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ patientProfile['rphfirstname'] }} </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search :key="refresh"></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>
      <div
        class="d-flex justify-center align-center"
      >
        <v-card-actions>
          <pg-form-buttons
            :step="activeStep"
            :final-step="3"
            @next="next"
            @previous="previous"
          ></pg-form-buttons>

          <!-- <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="success"
              large
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col> -->
          <v-col>
            <pg-submit
              v-show="activeStep === 3"
              id="v-step-7"
              form-type="adaptingRx"
              base-filename="adapting-rx"
              type-of-medscheck="typeOfMedscheck"
              :patient-data="patientProfile"
              @saveInfo="savePdfInfo($event)"
            >
              Submit
            </pg-submit>
            <save-patient-state
              :patient-profile="patientProfile"
              :label="saveMedscheck"
              @onload="OnLoad($event)"
              @click="statekeyRender"
            ></save-patient-state>
          </v-col>
        </v-card-actions>
      </div>
    </v-stepper>
    <v-dialog
      v-model="medSelectionDialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-card-title class="tw-my-2 tw-justify-center">
          This form only allows 1 drug to be selected, select a drug from your selection below:
        </v-card-title>
        <v-card-text>
          <ul class="tw-flex tw-flex-col tw-space-y-4">
            <v-chip
              v-for="drug in this.$store.state.Patient.selectedMedications"
              :key="drug.DIN"
              class="tw-w-1/2 tw-m-auto "
              @click="selectFromList([drug])"
            >
              <li>
                {{ drug.GenericName }} - {{ drug.BrandName }} - DIN:{{ drug.DIN }}
              </li>
            </v-chip>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AppCardActions from '@core/components/app-card-actions/AppCardActions.vue'
import { mdiAlert, mdiCheckboxMarkedCircleOutline } from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import DoctorSearch from '@/components/common/ui/Form/DoctorSearch/DoctorSearch.vue'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'
import SingleMedicationsSelection from '@/components/phoxTables/drug/singleDrug.vue'
import { stateService } from '@/render/api/zarya'
import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import Container from '@/components/common/ui/Layout/Container/Container.vue'
import Section from '@/components/common/ui/Layout/Section/Section.vue'

// import SingleMedicationsSelection from '../rxSearch/SingleMedicationsSelection.vue'
import PatientPanel from '@/views/patient/components/PatientPanel.vue'
import SavePatientState from '@/views/pages/tools/SaveDocumentState.vue'
import pgFormButtons from '@/views/patient/components/pg-form-buttons.vue'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'

export default {
  // setup() {
  //   const radios = ref('home')
  //   const panel = ref(0)

  //   return {
  //     radios,
  //     panel,
  //   }
  // },
  name: 'Adaptingrx',
  components: {
    'single-medication-selection': SingleMedicationsSelection,
    'pg-submit': submitPdf,
    'save-patient-state': SavePatientState,
    'pg-form-buttons': pgFormButtons,

    'pharmacist-search': PharmacistSearch,
    'doctor-search': DoctorSearch,
    'pg-section': Section,
    'pg-container': Container,
    'app-card-actions': AppCardActions,
    'patient-panel': PatientPanel,
    'pg-sig-input': pgSigInput,
  },
  props: [], // possible will include selected meds from the patient profile view (saved state)
  data() {
    this.$store.dispatch('setFormClass', 'adaptingRx')

    return {
      medSelectionDialog: false,
      saveMedscheck: 'Save Progress',
      icons: { mdiAlert, mdiCheckboxMarkedCircleOutline },
      panel: 0,
      finalpanel: 0,
      initial: '',
      canvaslocal: '',
      componentTopCardKey: 0,
      refresh: true,
      checkAll: false,
      adaptionChoice: '',
      adaptationChoices: ['Dose Change', 'Dosage Form', 'Regimen', 'ROA'],
      requiredCheckContent: ['requiredCheck1', 'requiredCheck2', 'requiredCheck3', 'requiredCheck4', 'optionalCheck1'],
      writtenResponses: [
        'doseReason',
        'originalDose',
        'newDose',
        'doseFormReason',
        'regimenReason',
        'originalRoute',
        'adaptedRoute',
        'roaReason',
      ],
      doseBoxes: ['doseIncrease', 'doseDecrease'],
      adaptionChoiceBoxes: ['Dose Change Box', 'Dosage Form Box', 'Regimen Box', 'ROA Box'],
      patientProfile: {},
      loadingPatient: false,
      activeStep: 1,
      componentKeyRefreshMedList: 0,
      componentKeyinteractions: 0,
      componentKeyMedList: 0,
      signatureCompKey: 0,
      submitDisabled: true,
      patientData: {},
      medicationsList: [],
      selectedMedication: [],
    }
  },
  computed: {
    ...mapGetters(['FormClass', 'UserProfile', 'PharmacyProfile']),
    ...mapState(['Patient', 'User']),
  },
  mounted() {
    if (this.$store.state.Patient.data.stateId) {
      if (this.$store.state.Patient.data.PatientSignature.signatureData) {
        this.patientProfile = {
          PatientSignature: {},
          PharmacistSignature: {},
        }
        this.selectedLoadPatient()
      } else {
        this.selectedLoadPatientNoSignature()
      }
    }

    // console.log(this.$store.state.Patient);
    this.preloadForm()
    this.selectedPatient()
    this.currentDate()
    this.currentDateTime()
    if (this.$store.state.Patient.selectedMedications.length > 1) {
      this.medSelectionDialog = true
    }
    if (this.$store.state.Patient.selectedMedications.length === 1) {
      this.selectedMedication = this.$store.state.Patient.selectedMedications
      this.activeStep = 2
    }

    // window.addEventListener('resize', this.resizeCanvas)
    // this.resizeCanvas()
    // FIX ME need to fix this damn unused patient ID
    this.$root.$on('Refresh_Table', patientID => {
      this.preloadForm()
      this.selectedPatient()
      this.panel = 0
      this.finalpanel = 0

      // this.componentKeyinteractions += 1
      // window.addEventListener('resize', this.resizeCanvas)
      // this.resizeCanvas()
    })
  },
  methods: {
    selectFromList(med) {
      this.selected(med)
      this.activeStep = 2
      this.medSelectionDialog = false
    },
    previous() {
      this.activeStep--
      if (this.activeStep === 2) {
        this.patientProfile.rphfirstname = `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`
        this.patientProfile.ocp = `${this.$store.state.User.user.ocp}`
        this.signatureCompKey++
      }
    },
    next() {
      this.activeStep++
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.patientProfile.rphfirstname = pharmacistName
      this.patientProfile.ocp = ocp
    },
    saveSignature({ pharmacistSig, signatureDate }) {
      this.patientProfile.PharmacistSignature = pharmacistSig
      this.patientProfile.rphsignature = pharmacistSig
      this.patientProfile.DATE = signatureDate
    },

    currentDate() {
      const current = new Date()
      const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`

      return date
    },
    currentDateTime() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`

      return dateTime
    },
    statekeyRender() {
      this.componentKeyinteractions += 1
    },
    nextstep() {
      this.activeStep = 3
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
        optionalCheck1: false,
        requiredCheck1: false,
        requiredCheck2: false,
        requiredCheck3: false,
        requiredCheck4: false,
        doseIncrease: false,
        doseDecrease: false,
        rphfirstname: '',
        ocp: '',
        doctorNotified: 'yes',
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
        optionalCheck1: false,
        requiredCheck1: false,
        requiredCheck2: false,
        requiredCheck3: false,
        requiredCheck4: false,
        doseIncrease: false,
        doseDecrease: false,
        rphfirstname: '',
        ocp: '',
        doctorNotified: 'yes',
      }
    },
    selectedPatient() {
      this.activeStep = 1
      this.componentTopCardKey += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
    },
    selectedLoadPatient() {
      this.activeStep = 1
      this.componentTopCardKey += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedLoadPatientNoSignature() {
      this.activeStep = 1
      this.componentTopCardKey += 1
      this.componentKeyinteractions += 1
      this.componentKeyMedList += 1
      this.patientProfile = {
        ...this.$store.state.Patient.data,
        PatientSignature: {},
        PharmacistSignature: {},
      }
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.patientProfile = {
        ...this.patientProfile,
        RXNUMBER: this.selectedMedication[0].RxNumber,
        RXSTRENGTH: this.selectedMedication[0].RxStrength,
        RXDATE: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
        BRANDNAME: this.selectedMedication[0].BrandName,
        GENERICNAME: this.selectedMedication[0].GenericName,
        DAYS: this.selectedMedication[0].Days,
        SIG: this.selectedMedication[0].SIGFull,
        RXQUANTITYDISPENSE: this.selectedMedication[0].RxQtyDispense,
        DIN: this.selectedMedication[0].DIN,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,
        'PRIMARY FAX #': this.selectedMedication[0].DoctorFax,
        'PRIMARY PHONE #': this.selectedMedication[0].DoctorPhone,
        'PRIMARY FIRST NAME': this.selectedMedication[0].FirstName,
        'PRIMARY LAST NAME': this.selectedMedication[0].LastName,
      }
      this.activeStep = 2
    },
    async downloadPDF(pdfRef) {
      const token = await this.$auth.getTokenSilently()
      this.$store.dispatch('pdfDownload', {
        token,
        id: pdfRef.id,
        filename: `${pdfRef.id}-${this.PatientProfile['FIRST NAME'].toLowerCase()}-${this.PatientProfile[
          'LAST NAME'
        ].toLowerCase()}`,
      })
    },
    savePdfInfo(data) {
      this.patientProfile.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.FormClass,
        state: this.patientProfile,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }

      // this.$router.push('/patient/patientdashboard')
      //   this.$router.push({
      //     name: 'dashboard-document',
      //     query: {
      //       tab: 1,
      //     },
      //   })
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }

        // eslint-disable-next-line no-undef
        res = await stateService.updateState(token, this.patientProfile.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }

      // update it - once medcheck is complete and it has state id, change to complete -- works
    },

    // to be checked
    openOverlayRxSearch(searchIndex) {
      window.searchRx.open(medication => {
        this.patientProfile = {
          ...this.patientProfile,
          RXNUMBER: medication.RxNumber,
          RXSTRENGTH: medication.RxStrength,
          RXDATE: this.$moment(medication.RxDate).format('YYYY-MM-DD'),
          BRANDNAME: medication.BrandName,
          GENERICNAME: medication.GenericName,
          RXQUANTITYDISPENSE: medication.RxQtyDispense,
          DAYS: medication.Days,
          SIG: medication.SIGFull,
        }
        this.patientProfile['PRIMARY FIRST NAME'] = medication.FirstName
        this.patientProfile['PRIMARY LAST NAME'] = medication.LastName
      })

      this.submitDisabled = this.patientProfile.RXNUMBER
    },
    dosageToggle(direction) {
      if (direction === 'increase') {
        this.patientProfile.doseIncrease = 'yes'
        this.patientProfile.doseDecrease = false
      } else {
        this.patientProfile.doseDecrease = 'yes'
        this.patientProfile.doseIncrease = false
      }
    },
    OnLoad(state) {
      let selectedMedication = []
      if (state.selectedMedication) {
        selectedMedication = state.selectedMedication

        // second selection
        this.medicationSubStep = 1

        // delete state.selectedMedication;
      }

      this.patientProfile = {
        ...this.patientProfile,
        ...state,
        selectedMedication,
      }

      // this.$store.dispatch('updatePatientData', this.patientProfile)
      this.refresh += 1
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
    },
    wordClickToggle(patientTag) {
      this.patientProfile[patientTag] = !this.patientProfile[patientTag]
      if (this.patientProfile[patientTag] && this.patientProfile[patientTag] !== 'yes') {
        this.patientProfile[patientTag] = 'yes'
      }
    },
    adaptationChoiceToggler() {
      this.consistancyKeeper(this.doseBoxes, false)
      this.consistancyKeeper(this.writtenResponses, '')
      this.consistancyKeeper(this.adaptionChoiceBoxes, false)
      this.patientProfile[`${this.adaptionChoice} Box`] = 'yes'
    },
    checkAllSecondary() {
      if (this.checkAll === true || this.checkAll === 'yes') {
        this.checkAll = false
        this.panel = 1
      } else {
        this.checkAll = 'yes'
        this.panel = 1
      }
      this.consistancyKeeper(this.requiredCheckContent, this.checkAll)
      this.panel = 1
    },
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.patientProfile = Object.assign(this.patientProfile, {
          rphfirstname: UserName,
          ocp: PharmID,
        })
      })
    },
    openDrOverlay() {
      window.searchDoctor.open(doctor => {
        this.patientProfile = Object.assign(this.patientProfile, {
          'PRIMARY FIRST NAME': doctor.DoctorFirstName,
          'PRIMARY LAST NAME': doctor.DoctorLastName,
        })
      })
    },
    consistancyKeeper(arr, consistantValue) {
      this.patientProfile = {
        ...this.patientProfile,
        ...arr.reduce(
          (acc, key) => ({
            ...acc,
            [key]: consistantValue,
          }),
          {},
        ),
      }
    },

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
    // resizeCanvas() {
    //   const ratio = Math.max(window.devicePixelRatio || 1, 1)
    //   const canvas = this.canvaslocal
    //   canvas.width = canvas.offsetWidth * ratio
    //   canvas.height = canvas.offsetHeight * ratio
    //   canvas.getContext('2d').scale(ratio, ratio)
    //   this.$refs.signaturePad.clearSignature() // otherwise isEmpty() might return incorrect value
    // },
  },
}
</script>

<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
// #checkallcheckbox {
//   border: double 3px transparent;
//   border-radius: 5px;
//   background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
//   background-origin: border-box;
//   background-clip: content-box, border-box;
// }
</style>
